/* * * * * * * * * */
/* Slider Styles   */
/* * * * * * * * * */

.slider{

  /** Default **/

  & .slick-list{
    height: 100%;

    & .slick-track{
      height: 100%;

      & .slick-slide{
        background-position: center !important;
        background-size: cover !important;
        height: 100%;
        position: relative;
      }
    }
  }

  & .slick-prev,
  & .slick-next{
    background: fade(@c-white, 50%);
    // bottom: 10px;
    top: 50px;
    z-index: 999;

    &.slick-arrow{
      background: @cb-pink;
      border: solid 2px @c-white;
      height: 50px;
      .mix-transition;
      width: 50px;

      &:before{
        color: @c-white;
        font: normal normal normal 28px/1 FontAwesome;
        .mix-transition;
      }
    }
  }

  & .slick-prev{
    border-radius: 50px 10px 10px 50px;
    left: initial;
    right: 100px;

    &.slick-arrow{

      &:before{
        content: "\f0d9" !important;
      }

    }
  }

  & .slick-next{
    border-radius: 10px 50px 50px 10px;
    right: 40px;

    &.slick-arrow{

      &:before{
        content: "\f0da" !important;
      }

    }
  }

  /** Custom **/

  & .slider-home{
    background: @cb-gray-light;
    min-height: 300px;
    max-height: 660px;
    .mix-transition(height);
    position: relative;

    &:after{
      background: linear-gradient(left, @gradient);
      content: "";
      height: 5px;
      left: 0%;
      position: absolute;
      top: 100%;
      width: 100%;
    }

    & .slick-slide{
      overflow: hidden;

      & .content{
        background: fade(@cb-pink, 60%);
        bottom: 0px;
        color: @c-black;
        left: 50%;
        .mix-transition(all, 2s);
        opacity: 0;
        padding: 10px 0px;
        padding-left: 100px;
        position: absolute;
        transform: translate(-50%, 0%);
        width: 100%;

        & .title{
          color: @cb-green;
          .fs-44;
          //text-shadow: 1px 1px 1px @c-shadow;
        }

        & .subtitle{
          .fs-16;
        }
      }

      &.slick-current{

        & .content{
          padding-left: 0px;
          opacity: 1;
        }
      }
    }
  }
}

/* * * * * * * * * */
/* Media queries   */
/* * * * * * * * * */

@media (max-width: @screen-l-min) {

  .slider{

    /** Default **/

    & .slick-prev,
    & .slick-next{
      display: none !important;
    }
  }
}

@media (max-width: @screen-m-min) {

  .slider{

     .slider-home{

      & .slick-slide{

        & .content{

          & .title{
            .fs-30;
          }
        }
      }
    }
  }
}

@media (max-width: @screen-s-min) {

  .slider{

     .slider-home{

      & .slick-slide{

        & .content{

          & .title{
            .fs-22;
          }

          & .subtitle{
            .fs-14;
          }
        }
      }
    }
  }
}


