/* * * * * * * * * */
/* DOM styles      */
/* * * * * * * * * */

  *{
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:focus{
      outline: none;
    }
  }

  html,body {
      color: @body-font-color;
      font-size: @body-font-size;
      background-color: @body-background-color;
      font-family: @base-font;
      font-weight: @base-font-weight-light;

      height:100%;
      margin:0 auto;
      padding:0px;
      position:relative;
      width:100%;
  }

  a{
      color: @main-link-color;
      cursor: pointer;
      text-decoration: none;
      .mix-transition;

      &:active,
      &:hover,
      &:focus{
          color: @main-link-color-hover;
      }
  }

  hr{
      border: none;
      border-top: solid 1px @separator-border-color;
      margin: 0px;
      padding: 0px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
      color: @headings-color;
      display: inline-block;
      font-family: @heading-font;
      font-weight: @base-font-weight-light;
      .fs-34;
      margin: 0px;
      position: relative;
      white-space: pre-line;
  }

  img{
    border: none;
    outline: none;
    vertical-align: middle;

    &.img-responsive{
      display: block;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  p{
    margin: 0px;
    padding: 5px 0px;
    white-space: pre-line;
  }

  .select-disabled{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .table{
    display: table;
    height: 100%;
    width: 100%;

    & .cell{
      display: table-cell;
      height: 100%;
      vertical-align: middle;
      width: 100%;
    }
  }

  .txt-light{
    font-weight: @base-font-weight-light;
  }

  .txt-normal{
    font-weight: @base-font-weight;
  }

  .txt-bold{
    font-weight: @base-font-weight-bold;
  }

  .txt-uppercase{
    text-transform: uppercase;
  }

  .txt-no-uppercase{
    text-transform: none;
  }

  .txt-cursive{
    font-style: italic;
  }

/* * * * * * * * * */
/* no-js: ;        */
/* * * * * * * * * */

  .no-js{

    body{
      padding-top: 52px;
      @media screen and (max-width: 767px) {
        padding-top: 72px;
      }
    }

    .noJS{
      display: block;
      width: 100%;

      &.alert{
        position: absolute;
        top: 0;
        z-index: 100000;
      }
    }
  }
