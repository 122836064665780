/* * * * * * * * * */
/* Forms styles    */
/* * * * * * * * * */


.input-field{
  background: @c-white;
  border-radius: 4px;
  position: relative;

  & input,
  & select,
  & textarea{
    appearance: none;
    background: transparent;
    border: solid 1px @cb-gray;
    border-radius: 4px;
    color: @cb-black;
    .fs-14;
    display: inline-block;
    .mix-transition(all, .5s, ease, width);
    padding: 20px 20px;
    padding-left: 35px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    z-index: 1;

    &:focus{
      border: solid 1px @cb-pink;
    }

    &.active,
    &:focus{

      & + label{
        color: transparent;

        & i{
          color: @cb-pink;
        }
      }
    }

    &.alert{
      border: solid 1px @cb-yellow;
    }

    &.error{
      border: solid 1px @cb-red;
    }

    &[class~="error"]{

      &.active,
      &:focus{
        border: solid 1px @cb-yellow;
      }
    }
  }

  & select{
    cursor: pointer;
  }

  & textarea{
    min-height: 140px;
    resize: none;
  }

  //--

  & label{
    color: @cb-black;
    .fs-14;
    left: 0px;
    max-width: 100%;
    .mix-transition(all, .2s);
    overflow: hidden;
    padding: 20px 20px;
    position: absolute;
    text-overflow: ellipsis;
    top: 0px;
    white-space: nowrap;
    z-index: 0;

    & i{
      color: @cb-black;
      .fs-16;
      margin-right: 10px;
      .mix-transition;
    }
  }

  //--

  &.with-ico{

    & input,
    & select,
    & textarea{
      padding: 10px 20px;
      padding-left: 40px;

      & + label{
        padding: 10px 20px;
      }

      &.active,
      &:focus{

        & + label{
          max-width: 70px;
        }
      }
    }
  }

  //--

  &.select{

    & select{
      padding-right: 25px;
    }

    &:before{
      bottom: 12px;
      content: "\f142";
      color: @cb-black;
      font: normal normal normal 20px/1 FontAwesome;
      margin-top: -8px;
      .mix-transition;
      position: absolute;
      right: 15px;
      top: 50%;
    }

    &:hover{

      &:before{
        color: @cb-pink;
      }
    }
  }
}
