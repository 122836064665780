/* * * * * * * * * */
/* Photos          */
/* * * * * * * * * */

.gallery{

  & .photo-card{
    position: relative;

    &:before,
    &:after{
      border: solid 2px transparent;
      content: "";
      height: 0%;
      .mix-transition;
      position: absolute;
      width: 0%;
      z-index: 0;
    }

    &:before{
      left: -2px;
      top: -2px;
    }

    &:after{
      bottom: -2px;
      right: -2px;
    }

    &:hover{

      &:before,
      &:after{
        height: 100%;
        width: 100%;
      }

      &:before{
        border: solid 2px @cb-green-light;
        border-right: none;
        border-bottom: none;
      }

      &:after{
        border: solid 2px @cb-pink;
        border-left: none;
        border-top: none;
      }
    }

    & .photo-image{
      overflow: hidden;
      position: relative;
      z-index: 1;

      & a.fbox-image{
        background-position: center !important;
        background-size: cover !important;
        display: block;
        filter: grayscale(75%);
        transform: scale(1);
        height: 100%;
        width: 100%;

        &:hover{
          filter: grayscale(0%);
          transform: scale(1.1);
        }
      }
    }
  }
}
