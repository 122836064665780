/* * * * * * * * * */
/* Fancybox styles */
/* * * * * * * * * */

.fancybox-overlay{
  background: fade(@c-black, 80%);

  & .fancybox-skin{
    padding: 0px !important;
  }
}

.fancybox-nav{
  background: fade(@c-white, 50%);
  height: 70px;
  margin-top: -35px;
  position: absolute;
  top: 50%;
  width: 70px;

  &:before{
    content: "";
    color: fade(@c-black, 70%);
    font: normal normal normal 34px/1 FontAwesome;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    .mix-transition;
  }

  &:hover{
    background: fade(@c-white, 80%);

    &:before{
      color: fade(@c-black, 100%);
    }
  }

  &.fancybox-next{

    &:before{
      content: "\f054";
    }
  }

  &.fancybox-prev{

    &:before{
      content: "\f053";
    }
  }
}

.fancybox-close{
  background: fade(@c-white, 50%);
  border-radius: 50%;

  &:before{
    content: "\f05c";
    color: fade(@c-black, 70%);
    font: normal normal normal 34px/1 FontAwesome;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    .mix-transition;
  }

  &:hover{
    background: fade(@c-white, 80%);

    &:before{
      color: fade(@c-red, 100%);
    }
  }
}
