/* * * * * * * * * */
/* General colors  */
/* * * * * * * * * */

  @c-black:         #000000;
  @c-blue:          #2196F3;
  @c-gray-lighter:  #EEEEEE;
  @c-gray-light:    #E0E0E0;
  @c-gray:          #BDBDBD;
  @c-gray-dark:     #757575;
  @c-gray-darker:   #424242;
  @c-green:         #4CAF50;
  @c-red:           #E53935;
  @c-shadow:        #424242;
  @c-yellow:        #FFC107;
  @c-white:         #FFFFFF;

/* * * * * * * * * */

/* * * * * * * * * */
/* Brand colors    */
/* * * * * * * * * */

@cb-black:          #8D8D8D;
@cb-black-dark:     #333333;
@cb-blue:           #299BC9;
@cb-gray-light:     #F8F8F8;
@cb-gray:           #DDDDDD;
@cb-gray-dark:      #777777;
@cb-green-light:    #007c74;
@cb-green:          #005f61;
@cb-green-dark:     #003a38;
@cb-orange:         #F8873B;
@cb-pink:           #ea0a54;
@cb-purple:         #975FA3;
@cb-red:            #C2413E;
@cb-yellow:         #F0DE3B;

@gradient:          @cb-pink,
                    @cb-green-light;


/* * * * * * * * * */
/* Classess        */
/* * * * * * * * * */

.bg-gray-light{
  background-color: @cb-gray-light;
}

.bg-gray{
  background-color: @cb-gray;
}

.cb-green{
  color: @cb-green;
}

.cb-green-light{
  color: @cb-green-light;
}

.cb-pink{
  color: @cb-pink;
}

