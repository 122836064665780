/* * * * * * * * * */
/* Nav styles      */
/* * * * * * * * * */

.main-menu{
  background: @c-white;
  border-bottom: solid 1px @cb-gray;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;
  // padding: 20px 0px;

  & .logo{

    & img{
      max-width: 115px;
      margin: 5px 0px;
      width: 100%;
    }
  }

  & .mobile{

    & a{
      display: inline-block;
      height: 30px;
      position: relative;
      vertical-align: middle;
      width: 30px;

      &:before,
      &:after{
        background: @cb-pink;
        border-radius: 3px;
        content: "";
        height: 6px;
        .mix-transition(all, .2s);
        position: absolute;
        right: 0px;
        width: 100%;
      }

      &:before{
        top: 0px;
      }

      &:after{
        bottom: 0px;
      }

      & .bars{
        background: @cb-pink;
        border-radius: 3px;
        height: 6px;
        margin-top: -3px;
        .mix-transition(all, .2s);
        position: absolute;
        right: 0px;
        top: 50%;
        width: 100%;
      }

      &.active,
      &:hover{

        & .bars{
          background: @cb-pink;
          border-radius: 50%;
          height: 8px;
          margin-top: -4px;
          width: 8px;
        }

        &:before,
        &:after{
          background: @cb-pink;
          border-radius: 50%;
          height: 8px;
          width: 8px;
        }
      }
    }
  }

  & nav{
    .mix-transition(right);

    & ul{
      .line;
      margin: 0px;
      padding: 0px;

      & li{
        display: inline-block;
        list-style: none;
        margin: 0px;
        padding: 0px;
        position: relative;
        vertical-align: middle;

        &:not(.button):after{
          background: @cb-gray;
          border-radius: 50%;
          content: "";
          height: 4px;
          left: 50%;
          .mix-transition;
          position: absolute;
          top: 100%;
          transform: translate(-50%, 0%);
          width: 4px;
        }

        & a{
          color: @cb-black;
          display: block;
          font-weight: @base-font-weight-bold;
          padding: 10px 8px;

          & i{
            display: none;
            padding-right: 5px;
          }
        }

        &.active,
        &:hover{

          &:after{
            background: @cb-pink;
            height: 6px;
            top: -2px;
            width: 6px;
          }

          & > a{
            color: @cb-pink;
          }
        }
      }
    }
  }
}

/* * * * * * * * * */
/* Media queries   */
/* * * * * * * * * */

@media (max-width: @screen-l-min){
  .main-menu{

    & nav{
      background: @cb-pink;
      box-shadow: -1px 0px 5px -2px transparent;
      height: 100%;
      padding: 30px 20px;
      position: fixed;
      right: -210px;
      //right: 0px;
      text-align: left;
      top: 0px;
      width: 210px;
      z-index: 999999;

      & .logo{
        padding: 30px 40px;
      }

      & ul{

        & li{
          border-radius: 5px;
          display: block;
          margin: 5px 0px;
          .mix-transition(background);

          &:after{
            display: none;
          }

          & a{
            color: @c-white;
            padding: 10px 15px;

            & i{
              display: inline-block;
            }
          }

          &.active,
          &:hover{
            background: fade(@c-black, 15%);

            & a{
              color: @c-white;
            }
          }
        }
      }

      &.active{
        box-shadow: -1px 0px 5px -2px @c-shadow;
        right: 0px;
      }
    }
  }
}
