/* * * * * * * * * */
/* About      */
/* * * * * * * * * */

.home{

  & .about-benbunan{
    background-color: @cb-pink;
    background-position: center;
    background-size: cover;
    background-image: url();

    & *{
      color: @c-white;
    }

    & .count-bar{
      background: @cb-gray-dark;
      height: 3px;
      margin-bottom: 15px;
      position: relative;
      width: 100%;

      &:after{
        background: @c-white;
        content: "";
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0px;
      }

      &.percent-75{

        &:after{
          width: 65%;
        }
      }

      &.percent-60{

        &:after{
          width: 100%;
        }
      }

      &.percent-90{

        &:after{
          width: 80%;
        }
      }
    }
  }
}
