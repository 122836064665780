/* * * * * * * * * */
/* Dropdowns styles */
/* * * * * * * * * */

.dropdown{

  & ul{
    .line;
    margin: 0px;
    padding: 0px;

    & li{

      &.drop-element{
        border-bottom: solid 2px @cb-gray-light;
        display: block;
        list-style: none;
        margin: 0px;
        padding: 0px;
        padding-right: 20px;
        position: relative;

        &:before{
          content: "\f067";
          cursor: pointer;
          color: @cb-pink;
          font: normal normal normal 14px/1 FontAwesome;
          .mix-transition();
          position: absolute;
          right: 5px;
          top: 21px;
        }

        &:after{
          background: linear-gradient(left, @cb-green, @cb-blue, @cb-purple, @cb-pink, @cb-red, @cb-orange, @cb-yellow);
          bottom: -2px;
          content: "";
          height: 2px;
          left: 0%;
          .mix-transition(width);
          position: absolute;
          width: 0%;
        }

        & .drop-text{
          color: @cb-black;
          cursor: pointer;
          display: block;
          .fs-18;
          padding: 15px 0px;
        }

        & .drop-description{
          display: none;
          padding: 5px 0px 15px 0px;
        }

        &.active,
        &:hover{

          & .drop-text{
            color: @cb-pink;
          }
        }

        &.active{

          &:before{
            content: "\f068";
          }

          &:after{
            width: 100%;
          }
        }
      }
    }
  }
}
