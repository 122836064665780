/* * * * * * * * * */
/* Example file    */
/* * * * * * * * * */

.slogan{
  display:block;
  font-size: 35px;
  color: #007c74;
}

@media (max-width:@screen-m-min) {
  .slogan{
    font-size: 22px;
  }
}



/* baner About us */

.banner{
  .special-team{
    min-height: auto !important;
    .special-team-img{
      background-repeat: no-repeat;
    }
  }
}

@media (min-width:@screen-l-min) {
  .banner{
    .special-team{
      height: 850px;
      .special-team-img{
        width: 80%;
        margin: 0px auto;
      }
    }
  }
}

@media (max-width:@screen-m-min) {
  .banner{
    .special-team-img{
      min-height: 250px !important;
    }
  }
}
