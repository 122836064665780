/* * * * * * * * * */
/* Fonts Variables */
/* * * * * * * * * */

  @increase_font: 2;
  @max-font-size: 80;
  @min-font-size: 6;

/* * * * * * * * * */
/* Fonts src       */
/* * * * * * * * * */

  @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600');

  // Uncomment if you are using font-awesome
  @import "../../bower_components/font-awesome/less/font-awesome";
  @fa-font-path: "../fonts/vendor";

/* * * * * * * * * */
/* Fonts size */
/* * * * * * * * * */

  .mix-make-font-size(@max-font-size);
