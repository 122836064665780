
.home{

  .slider-home{

    .slick-slide{

      &.slick-active{
        .title{
          bottom: 0px;
        }
      }
    }
  }
}

  .visible-x{
    display: none;
  }

  // Media query

  @media (max-width: 768px) {
    .hidden-x{
      display: none;
    }
    .visible-x{
      display: block;
    }

  }
