/* * * * * * * * * */
/* Mixins styles   */
/* * * * * * * * * */

/* * * * * * * * * */
/* Fonts Size      */
/* * * * * * * * * */

  /* Example:
    If @body-font-size = 16px
    then .fs-16 = 16px */

  /* Available fonts
    From .fs-6 to the .fs-80 Increasing in pairs */

  .mix-make-font-size(@available_size) {

    .font-size-generate(@n, @i: @min-font-size) when (@i =< @n) {

      .fs-@{i} {
        font-size: (@i/@body-font-size) * 1rem;
      }
      .font-size-generate(@n, (@i + @increase_font));
    }

    .font-size-generate(@available_size);
  }

/* * * * * * * * * */
/* Transitions     */
/* * * * * * * * * */

  .mix-transition(
      @property: all,
      @time: .5s,
      @dir: ease,
      @cancel: outline
    ){
    transition: @property @time @dir, @cancel .000001s;
  }

/* * * * * * * * * */

/* * * * * * * * * */
/* Buttons     */
/* * * * * * * * * */

.mix-btn--color(
  @background: transparent,
  @color: @cb-black,
  @color-hover: @cb-black,
  @border: transparent
){
  background: @background;
  border: solid 1px @border;
  color: @color;

  & i{
    color: @color;
  }

  &:hover{
    background: darken(@background, 10%);
    border: solid 1px darken(@border, 10%);
    color: @color-hover;

    & i{
      color: @color-hover;
    }
  }
}

