/* * * * * * * * * */
/* Banners Styles  */
/* * * * * * * * * */

.banner{
  max-height: 500px;
  position: relative;

  //--

  &.banner--blue{
    background: @cb-blue;

    *{
      color: @c-white;
    }
  }

  &.banner--purple{
    background: @cb-purple;

    *{
      color: @c-white;
    }
  }

   &.banner--green{
    background: @cb-green;

    *{
      color: @c-white;
    }
  }

  &.banner--grey-light{
    background: @cb-gray-light;
  }

  &.banner--pink{
    background: @cb-pink;

    *{
      color: @c-white;
    }
  }

  //--

  & .banner-inside{
    min-height: 250px;
    max-height: 400px;
  }

  & .banner-background{
    background-position: center !important;
    // background-position-y: 30% !important;
    background-size: cover !important;
    height: 100%;
    min-height: 350px;
    max-height: 600px;
  }

  & .banner-content{
    display: inline-block;
    //max-width: @container / 2;
    width: 100%;
    padding: 20px 30px;
    vertical-align: middle;

    & p{
      .fs-30;
    }
  }

  //--

  &.banner--single{

    &:after{
      background: linear-gradient(left, @gradient);
      content: "";
      height: 5px;
      left: 0%;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 99;
    }
  }

  &.banner--with-text{

    &:before{
      background: fade(@c-black, 60%);
      content: "";
      height: 100%;
      left: 0%;
      position: absolute;
      top: 0%;
      width: 100%;
      z-index: 99;
    }

    & .banner-content{
      display: inline-block;
      left: 50%;
      max-width: 100%;
      padding: 20px 30px;
      position: absolute;
      text-align: center;
      transform: translate(-50%, -50%);
      top: 50%;
      vertical-align: middle;
      width: 100%;
      z-index: 100;
    }

    & a{

      &:hover{
        color: @cb-green;
      }
    }
  }
}

.slick-testimonials{
  .testimonial-home{
    .t-autor{
      .fs-20;
      font-style: italic;
    }
  }
}


/* Check */

.with-float-button{
  position: relative;
  .m-button-more{
    top: 50px;
    margin-right: -130px;
    right: 50%;
    position: absolute;
    z-index: 300;
  }
}



/* * * * * * * * * */
/* Media queries  */
/* * * * * * * * * */

@media (max-width: @screen-m-min){

  .banner{

    & .banner-background{
      min-height: auto;
      //height: 300px;
    }

    & .banner-content{
      height: auto !important;
      max-width: 100%;
      padding: 20px 20px;

      & p{
        .fs-22;
      }
    }

    //--

    &.banner--with-text{

      & .banner-background{
        max-height: auto;
      }
    }
  }
}


@media (max-width: @screen-s-min){

  .banner{

    & .banner-background{
      max-height: auto;
    }

    & .banner-content{
      height: auto !important;

      & p{
        .fs-18;
      }
    }
  }
}
