/* * * * * * * * * */
/* Captcha styles  */
/* * * * * * * * * */


.g-recaptcha{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle;

 /* & > div{
    width: 100% !important;

    & > div{
      width: 100% !important;

      & iframe{
        width: 100% !important;
      }

      & .rc-anchor{
        border: solid 1px @cb-gray !important;
        box-shadow: none !important;
        width: 100% !important;
      }
    }
  }*/
}
