/* * * * * * * * * */
/* Treatments Styles */
/* * * * * * * * * */

.home{

  & .treatments{

    & .treatment-card{
      background: @c-white;
      border: solid 1px @cb-gray;
      height: 100%;
      text-align: center;

      & .treatment-picture{

        & img{
          width: 100%;
        }
      }

      & .treatment-content{
        padding: 20px;
      }

      & a{
        display: block;
        margin-top: 20px;
      }
    }
  }
}
