/* * * * * * * * * */
/* Map styles      */
/* * * * * * * * * */

.map-container{
  height: 650px;
  position: relative;
  //width: 100%;

  &:after{
    background: linear-gradient(left, @cb-green, @cb-blue, @cb-purple, @cb-pink, @cb-red, @cb-orange, @cb-yellow);
    content: "";
    height: 5px;
    left: 0%;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  & .map{
    height: 100%;
    width: 100%;
  }
}

.to-go{
  display: none;
  .txt-uppercase;
  .fs-18;
  vertical-align: middle;
}

/* * * * * * * * * */
/* Media queries   */
/* * * * * * * * * */

@media (max-width: @screen-l-min){
  .map-container{
    height: 550px;
  }
}

@media (max-width: @screen-m-min){
  .map-container{
    height: 450px;
  }
}

@media (max-width: @screen-s-min){
  .map-container{
    height: 250px;
  }
}
