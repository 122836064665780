/* * * * * * * * * */
/* Buttons styles  */
/* * * * * * * * * */

.btn{
  border: solid 1px @cb-black;
  border-radius: 4px;
  color: @cb-black;
  cursor: pointer;
  display: inline-block;
  .fs-14;
  margin-bottom: 5px;
  margin-top: 5px;
  .mix-transition(all, .5s, ease, width);
  padding: 10px 25px;
  text-align: center;
  .txt-uppercase;
  vertical-align: middle;

  & i{
    .fs-20;
    margin-right: 5px;
    .mix-transition;
  }

  //--

  &.btn--full{
    width: 100%;
  }

  &.btn--big{
    .fs-16;
    padding: 15px 25px;
  }

  &.btn--small{
    .fs-12;
    padding: 5px 15px;
  }

  &.btn--icon{

    & i{
      margin-right: 0px;
    }
  }

  /** Colors **/

  &.btn--pink{
    .mix-btn--color(
      @cb-pink,
      @c-white,
      @c-white,
      transparent
    );
  }

  &.btn--green{
    .mix-btn--color(
      @cb-green,
      @c-white,
      @c-white,
      transparent
    );
  }

  &.btn--purple{
    .mix-btn--color(
      @cb-purple,
      @c-white,
      @c-white,
      transparent
    );
  }

  &.btn--blue{
    .mix-btn--color(
      @cb-blue,
      @c-white,
      @c-white,
      transparent
    );
  }

  &.btn--white-o{
    .mix-btn--color(
      transparent,
      @c-white,
      @cb-pink,
      transparent
    );
  }
}

.btn-waze[href='#'],
.btn-google-maps[href='#']{
  display: none;
}
