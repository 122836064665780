cookie {
  background: #fff;
  border: solid 1px #237c74;
  bottom: 30px;
  box-shadow: 0px 3px 5px -2px fade(#000, 20%);
  display: none;
  left: 50%;
  max-width: 500px;
  padding: 20px;
  padding-top: 30px;
  position: fixed;
  transform: translate(-50%, 0%);
  width: auto;
  z-index: 999;

  & .close {
    color: #333;
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 10px;

    &:hover {
      color: #333;
    }
  }

  & p {
    white-space: pre-line;
  }

 /* @media (max-width: 599px) {
    right: 50%;
    transform: translate(50%, 0%);
    width: 100%;
  }*/
}
