/* * * * * * * * * */
/* Messages styles */
/* * * * * * * * * */

.messages{
  background: fade(@c-black, 60%);
  height: 100%;
  left: 0px;
  padding: 20px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999999;

  & .alert{
    background: @c-white;
    border-bottom: solid 5px @c-blue;
    box-shadow: 0px 0px 5px -2px @c-shadow;
    left: 50%;
    max-height: 80%;
    max-width: 80%;
    min-width: 280px;
    overflow: auto;
    padding: 10px;
    position: absolute;
    top: 100px;
    transform: translate(-50%, 0%);

    & ul{
      margin: 0px;
      padding: 0px;

      & li{
        border-bottom: solid 1px @c-gray-light;
        list-style: none;
        margin: 0px;
        padding: 5px 10px;
        padding-left: 10px;
        position: relative;
      }
    }

    &.alert-danger{
      border-bottom: solid 5px @c-red;

      & *{
        color: @c-red;
      }
    }

    &.alert-success{
      border-bottom: solid 5px @c-green;

      & *{
        color: @c-green;
      }
    }

    //--

    & .state{
      margin-bottom: 15px;
      padding: 0px 10px;

      &.danger{
        background: fade(@c-red, 5%);
      }

      &.success{
        background: fade(@c-green, 5%);
      }

      & .close{
        border-radius: 4px;
        display: inline-block;
        .fs-30;
        text-align: center;
        vertical-align: middle;

        & i{
          color: @c-black;
          .mix-transition(color);
        }

        &:hover{

      & i{
            color: @c-red;
          }
        }
      }
    }
  }
}
