/* * * * * * * * * */
/* Footer styles   */
/* * * * * * * * * */

.schedules-bar{
  background: @cb-green-light;
  color: @c-white;

  & a {
    color: @c-white;

    &:hover {
      color: @cb-pink;
    }
  }
}

.footer{
  background: @cb-gray-light;
  padding: 20px 0px;

  & a{
    color: @cb-black;

    &:hover{
      color: @cb-pink;
    }
  }

  & p{
    color: @cb-black;

    & a{
      display: inline-block;
      padding: 2px 5px;
      vertical-align: middle;
    }
  }

  & .logo{

    & img{
      max-width: 115px;
      width: 100%;
    }
  }



  & nav{
    .mix-transition(right);

    & ul{
      .line;
      margin: 0px;
      padding: 0px;

      & li{
        display: inline-block;
        list-style: none;
        margin: 0px;
        padding: 0px;
        position: relative;

        &:after{
          background: @cb-gray;
          border-radius: 50%;
          content: "";
          height: 4px;
          left: 50%;
          .mix-transition;
          position: absolute;
          top: 100%;
          transform: translate(-50%, 0%);
          width: 4px;
        }

        & a{
          color: @cb-black;
          display: block;
          font-weight: @base-font-weight-bold;
          padding: 10px 8px;

          & i{
            display: none;
            padding-right: 5px;
          }
        }

        &.active,
        &:hover{

          &:after{
            background: @cb-pink;
            height: 6px;
            top: -2px;
            width: 6px;
          }

          & a{
            color: @cb-black-dark;
          }
        }
      }
    }
  }
}

.footer-top{
  padding: 10px 0px;
}
