/* * * * * * * * * */
/* Treatments cards  */
/* * * * * * * * * */

.treatments{

  & .treatment-card{
    background: @c-white;
    border: solid 1px @cb-gray;
    height: 100%;
    position: relative;
    text-align: center;

    &:before,
    &:after{
      border: solid 2px transparent;
      content: "";
      height: 0%;
      .mix-transition;
      position: absolute;
      width: 0%;
      z-index: 0;
    }

    &:before{
      border-right: none;
      border-bottom: none;
      left: -2px;
      top: -2px;
    }

    &:after{
      border-left: none;
      border-top: none;
      bottom: -2px;
      right: -2px;
    }

    &:hover{

      &:before,
      &:after{
        height: 100%;
        width: 100%;
      }

      &:before{
        border: solid 2px @cb-green-light;
        border-right: none;
        border-bottom: none;
      }

      &:after{
        border: solid 2px @cb-pink;
        border-left: none;
        border-top: none;
      }
    }

    & .treatment-picture{
      position: relative;
      z-index: 1;

      & img{
        width: 100%;
      }
    }

    & .treatment-content{
      position: relative;
      padding: 20px;
      z-index: 1;
    }
  }

  & ul{

    & li{
      margin: 10px 0px;
      padding: 0px;
    }
  }
}
