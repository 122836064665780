/* * * * * * * * * */
/* Team Cards      */
/* * * * * * * * * */

.team-card{

  & .profile-picture{
    background-position: top !important;
    background-size: cover !important;
    border-radius: 50%;
    border-bottom: solid 3px @cb-pink;
    border-left: solid 3px @cb-green-light;
    border-right: solid 3px @cb-pink;
    border-top: solid 3px @cb-green-light;
    height: 160px;
    display: inline-block;
    .mix-transition;
    transform: scale(1);
    width: 160px;
  }

  &:hover{

    & .profile-picture{
      border-bottom: solid 3px @cb-green-light;
      border-left: solid 3px @cb-pink;
      border-right: solid 3px @cb-green-light;
      border-top: solid 3px @cb-pink;
      transform: scale(1.2);
    }
  }
}
