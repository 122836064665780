/* * * * * * * * * */
/* Directories     */
/* * * * * * * * * */

.stores{

  & .stores-directory{

    & .store-details{

      & .store-name{

        & p{
          color: @cb-pink;
          .fs-18;
        }
      }

      & .store-address,
      & .store-schedules{

        & .head{
          color: @c-black;
          .txt-bold;
        }
      }

      & .store-reference{

        & p{
          color: @c-black;
          .txt-bold;
        }
      }
    }
  }
}
