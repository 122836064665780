/* * * * * * * * * * * * * */
/* Colored Squares Styles  */
/* * * * * * * * * * * * * */

.colored-squares{

  & .colored-square{
    padding: 30px;

    & .square-number{
      border: solid 2px @cb-gray;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      position: relative;
      width: 30px;

      &:after{
        content: attr(number);
        .fs-16;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        .txt-bold;
      }
    }

    //--

    &.colored-square--blue{

      & .square-number{
        border: solid 2px @cb-blue;

        &:after{
          color: @cb-blue;
        }
      }
    }

    &.colored-square--green{

      & .square-number{
        border: solid 2px @cb-green;

        &:after{
          color: @cb-green;
        }
      }
    }

    &.colored-square--orange{

      & .square-number{
        border: solid 2px @cb-orange;

        &:after{
          color: @cb-orange;
        }
      }
    }

    &.colored-square--pink{

      & .square-number{
        border: solid 2px @cb-pink;

        &:after{
          color: @cb-pink;
        }
      }
    }

    &.colored-square--purple{

      & .square-number{
        border: solid 2px @cb-purple;

        &:after{
          color: @cb-purple;
        }
      }
    }

    &.colored-square--red{

      & .square-number{
        border: solid 2px @cb-red;

        &:after{
          color: @cb-red;
        }
      }
    }
  }
}
