/* * * * * * * * * * * * */
/* Datepicker styles     */
/* * * * * * * * * * * * */

.ui-datepicker{
  margin-top: 10px;

  & .ui-datepicker-header{
    background: @cb-gray-light;
    border: none;
  }

  & .ui-state-default{
    background: @cb-gray-light;
    border: none;
    color: @cb-pink;
  }

  & .ui-state-hover{
    background: @cb-pink;
    border: none;
    color: @c-white;
  }

  & .ui-icon{
    overflow: visible;
    text-indent: 0px;
  }
}
