/* * * * * * * * * */
/* Mods styles     */
/* * * * * * * * * */

h1,
h2,
h3,
h4,
h5,
h6{

}

h3{
  .fs-24;
}

.map{
  max-height: 400px;
  width: 100%;
}


.ticket{
  max-width: 350px !important;
}

.g-recaptcha{
  display: inline-block;
}

.border-right {
  border-right: solid 1px #ECECEC;
}

.border-top {
  border-top: solid 1px #ECECEC;
}

/* * * * * * * * * */
/* Media queries   */
/* * * * * * * * * */

@media (max-width: @screen-l-min){

  .smain-container,
    footer{
      left: 0px;
      .mix-transition(left);
      position: relative;

      &.active{
        left: -210px;
      }
    }

}
