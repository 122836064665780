/* * * * * * * * * */
/* Testimonials    */
/* * * * * * * * * */

.testimonials{

  & .testimonial-card{
    position: relative;

    & .testimonial-head{
      background: @cb-green-light;
      border-radius: 4px 4px 4px 0px;
      color: @c-white;
      padding: 10px 20px;
      position: relative;

      &:before{
        border: solid 8px @cb-green-light;
        border-right: solid 8px transparent;
        border-bottom: solid 8px transparent;
        content: "";
        left: 0px;
        position: absolute;
        top: 100%;
      }
    }

    & .testimonial-content{
      background: @c-white;
      border-left: solid 2px @cb-pink;
      box-shadow: 0px 0px 5px -2px @c-shadow;
      margin-top: 20px;
      padding: 20px;
    }
  }
}
